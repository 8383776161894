import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby-plugin-intl";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import get from "lodash-es/get";
import Layout from "../components/layout";
import SEO from "../components/seo";

import { RICH_TEXT_RENDER_OPTIONS } from "../constants";

import ArrowBackwardIcon from "../images/icons/arrow_backward.inline.svg";

const JobTemplate = (props) => {
  const job = get(props, "data.contentfulJob", {});

  return (
    <Layout>
      <SEO title={`${job.title} | Careers | Younics`} />
      <div className="container pt-32 md:pt-52">
        <h1 className="text-5xl md:text-7xl font-600 leading-none pb-10 text-center">
          Looking for <span className="text-red-bright">you</span> in Younics
        </h1>
        <p className="text-lg md:text-xl max-w-3xl leading-loose mx-auto text-center pb-20 md:pb-24 text-gray-mid">
          Tired of messy code, boring projects, poorly designed architecture,
          corporate restrictions or lack of vision, strategy and management?
          Let’s talk.
        </p>

        <div className="pb-6">
          <Link
            to="/career"
            className="flex items-center  transition-colors group"
          >
            <ArrowBackwardIcon className="flex-shrink-0 fill-current  text-gray-mid group-hover:text-purple transition duration-500 ease-in-out transform group-hover:-translate-x-2" />
            <span className="pl-2  text-gray-mid group-hover:text-transparent bg-clip-text  bg-gradient-to-r from-purple to-blue pt-0.5">
              All Open Positions
            </span>
          </Link>
        </div>

        <div className="bg-gradient-to-b from-purple-dark-a50 to-transparent p-6 md:p-12 lg:p-16 md:pb-0 lg:pb-0 lg:rounded-tr-5xl">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-500 pb-8 lg:pb-16 xl:pb-20">
            {job.title}
          </h2>
          <div className="">
            <div className="grid grid-cols-4 md:gap-6 lg:gap-9">
              {job.salary ? (
                <div className="col-span-4 lg:col-span-1 pb-7 lg:pb-10">
                  <h3 className="text-sm inline-block font-600 text-gray-mid pb-2 md:pb-4">
                    Compensation
                  </h3>
                  <div className="text-lg lg:text-xl font-600 pb-3">
                    {job.salary}
                  </div>
                  <span className="text-sm text-white">
                    (re-evaluated every 6 months)
                  </span>
                </div>
              ) : null}

              <div className="col-span-4 lg:col-span-1 lg:pl-10">
                {job.contract ? (
                  <div className="pb-7 lg:pb-10">
                    <h3 className="text-sm inline-block font-600 text-gray-mid pb-2 md:pb-4">
                      Contract
                    </h3>
                    <div className="text-lg lg:text-xl pb-3">
                      {job.contract}
                    </div>
                  </div>
                ) : null}

                {job.type ? (
                  <div className="pb-7 lg:pb-10">
                    <h3 className="text-sm inline-block font-600 text-gray-mid pb-2 md:pb-4">
                      Type
                    </h3>
                    <div className="text-lg lg:text-xl pb-3">{job.type}</div>
                  </div>
                ) : null}
              </div>

              <div className="col-span-4 lg:col-span-2 ">
                {job.techStack ? (
                  <div className="pb-7 lg:pb-10 max-w-xl">
                    <h3 className="text-sm inline-block font-600 text-gray-mid pb-2 md:pb-4">
                      Tech Stack
                    </h3>
                    <div className="text-lg md:text-xl pb-3">
                      {job.techStack.map((tech, index) => (
                        <span key={index}>
                          {tech}
                          {index !== job.techStack.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </div>
                  </div>
                ) : null}

                {job.toolStack ? (
                  <div className="pb-10 lg:pb-12 xl:pb-20 max-w-xl">
                    <h3 className="text-sm inline-block font-600 text-gray-mid pb-2 md:pb-4">
                      Tool Stack
                    </h3>
                    <div className="text-lg lg:text-xl pb-3">
                      {job.toolStack.map((tech, index) => (
                        <span key={index}>
                          {tech}
                          {index !== job.toolStack.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-12">
            {job.description ? (
              <div className="max-w-lg pb-10">
                {documentToReactComponents(
                  job.description.json,
                  RICH_TEXT_RENDER_OPTIONS
                )}
              </div>
            ) : null}

            {job.offer ? (
              <div className="max-w-lg pb-10">
                <h3 className="text-sm inline-block font-600 text-gray-mid pb-4 md:pb-6">
                  Offer
                </h3>
                {documentToReactComponents(
                  job.offer.json,
                  RICH_TEXT_RENDER_OPTIONS
                )}
              </div>
            ) : null}

            {job.mindset ? (
              <div className="max-w-lg pb-10">
                <h3 className="text-sm inline-block font-600 text-gray-mid pb-4 md:pb-6">
                  Mindset
                </h3>
                {documentToReactComponents(
                  job.mindset.json,
                  RICH_TEXT_RENDER_OPTIONS
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default JobTemplate;

export const pageQuery = graphql`
  query JobBySlug($slug: String!, $locale: String) {
    contentfulJob(node_locale: { eq: $locale }, slug: { eq: $slug }) {
      id
      title
      slug
      contract
      type
      location
      salary
      # description {
      #   json
      # }
      mindset {
        json
      }
      offer {
        json
      }
      techStack
      toolStack
    }
  }
`;
